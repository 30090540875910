import ResourceService from '@/services/resource.service';
import axios from 'axios';

export default class InvoiceCategoryService extends ResourceService {
    constructor(params = {}) {
        super();
    }

    getAll() {
        return axios.get('api/v1/invoice_categories');
    }
}
