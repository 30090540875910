import ResourceService from '@/services/resource.service';
import axios from 'axios';
import { serialize } from '@/utils/url';

export default class TasksService extends ResourceService {
    getAll() {
        return axios.get('tasks/list');
    }

    getItem(id, filters = {}) {
        return axios.post(this.getItemRequestUri(id), filters);
    }

    getItemRequestUri(id) {
        return `api/v1/tasks/${id}`;
    }

    getDashboardTasks(userID) {
        return axios.get(`tasks / dashboard ? ${serialize({ user_id: userID, with: ['project'] })} `);
    }

    getWithFilters(filters, config = {}) {
        return axios.post('api/v1/task/list', filters, config);
    }

    deleteItem(id) {
        return axios.delete(`api/v1/tasks/${id}?id=${id}`);
    }

    save(data, isNew = false) {
        return axios.post('api/v1/tasks', data);
    }

    update(data, id) {
        return axios.put(`api/v1/tasks/${id}`, data);
    }

    getOptionLabelKey() {
        return 'task_name';
    }

    async fetchTasks() {
        return await axios.post('api/v1/task/list', { disable_pagy: true });
    }
}
