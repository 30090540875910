import axios from 'axios';

export default class CompanyService {
    async update(params) {
        return await axios.patch('api/v1/current_company', params);
    }

    async getById(id) {
        const { data } = await axios.get(`/api/v1/companies/${id}`);
        return data.data.attributes;
    }
}
