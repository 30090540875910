import TaskService from '@/services/resource/task.service.js';

const state = {
    tasks: null,
    service: null,
};

const getters = {
    tasks: s => s.tasks,
    service: s => s.service,
};

const mutations = {
    setTasksData(s, tasksData) {
        s.tasks = tasksData;
    },
    setService(s, service) {
        s.service = service;
    },
};

const actions = {
    init(context) {
        context.commit('setService', new TaskService());
    },
    setTasksData({ commit }, tasks) {
        commit('setTasksData', tasks);
    },
    async getTasks({ dispatch, getters }) {
        let tasks = getters.tasks;
        if (!tasks) {
            await dispatch('fetchTaskData');
            tasks = getters.tasks;
        }
        return tasks;
    },
    async fetchTaskData({ commit, getters }) {
        const { service } = getters;
        if (service) {
            const { data } = await service.fetchTasks();
            const tasks = data.data;
            commit('setTasksData', tasks);
            return tasks;
        }
        return null;
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
