const state = {
    langList: {
        en: 'English',
        ru: 'Русский',
        ua: 'Україньська',
        pl: 'Polski',
        fr: 'Français',
        de: 'Deutsch',
    },
};

const getters = {
    langList: s => s.langList,
};

const mutations = {
    lang(s, { code, label }) {
        s.langList[code] = label;
    },
};

const actions = {
    setLang({ commit }, { code, label }) {
        commit('lang', { code, label });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
