import ProjectService from '@/services/resource/project.service.js';

const state = {
    projects: null,
    service: null,
};

const getters = {
    projects: s => s.projects,
    service: s => s.service,
};

const mutations = {
    setProjectsData(s, tasksData) {
        s.projects = tasksData;
    },
    setService(s, service) {
        s.service = service;
    },
};

const actions = {
    init(context) {
        context.commit('setService', new ProjectService());
    },
    setProjectsData({ commit }, projects) {
        commit('setProjectsData', projects);
    },
    async getProjects({ dispatch, getters }) {
        let projects = getters.projects;
        if (!projects) {
            await dispatch('fetchProjectData');
            projects = getters.projects;
        }
        return projects;
    },
    async fetchProjectData({ commit, getters }) {
        const { service } = getters;
        if (service) {
            const { data } = await service.getAll();
            const projects = data.data;
            commit('setProjectsData', projects);
            return projects;
        }
        return null;
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
