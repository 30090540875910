import InvoiceCategoryService from '@/services/resource/invoice_category.service.js';

const state = {
    invoiceCategories: null,
    service: null,
};

const getters = {
    invoiceCategories: s => s.invoiceCategories,
    service: s => s.service,
};

const mutations = {
    setInvoiceCategoriesData(s, data) {
        s.invoiceCategories = data;
    },
    setService(s, service) {
        s.service = service;
    },
};

const actions = {
    init(context) {
        context.commit('setService', new InvoiceCategoryService());
    },
    setInvoiceCategoriesData({ commit }, data) {
        commit('setInvoiceCategoriesData', data);
    },
    async getInvoiceCategories({ dispatch, getters }) {
        let invoiceCategories = getters.invoiceCategories;
        if (!invoiceCategories) {
            await dispatch('fetchInvoiceCategoriesData');
            invoiceCategories = getters.invoiceCategories;
        }
        return invoiceCategories;
    },
    async fetchInvoiceCategoriesData({ commit, getters }) {
        const { service } = getters;
        if (service) {
            const { data } = await service.getAll();
            const invoiceCategories = data.data;
            commit('setInvoiceCategoriesData', invoiceCategories);
            return invoiceCategories;
        }
        return null;
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
