import axios from 'axios';

export default class NotificationService {
    async getAll(filters) {
        return await axios.get('api/v1/notifications', filters);
    }

    async update(id, params) {
        return axios.put(`api/v1/notifications/${id}`, params);
    }

    async approve(params) {
        return axios.put(`api/v1/approvals_objects/approve`, params);
    }

    async reject(params) {
        return axios.put(`api/v1/approvals_objects/reject`, params);
    }
}
