import ResourceService from '@/services/resource.service';
import axios from 'axios';

export default class UsersService extends ResourceService {
    /**
     * @param config
     * @returns {Promise<AxiosResponse<T>>}
     */
    async getAll(config = {}) {
        return await axios.post('api/v1/users/list', config);
    }

    /**
     * @param id
     * @returns string
     */
    getItemRequestUri(id) {
        return `api/v1/users/${id}`;
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    getItem(id) {
        return axios.get(this.getItemRequestUri(id));
    }

    /**
     * @param data
     * @param isNew
     * @returns {Promise<AxiosResponse<T>>}
     */
    save(data, isNew = false) {
        return axios.post(`api/v1/users`, data);
    }

    update(data, id) {
        return axios.put(`api/v1/users/${id}`, data);
    }

    /**
     * @param id
     * @returns {Promise<AxiosResponse<T>>}
     */
    deleteItem(id) {
        return axios.post('users/remove', { id });
    }

    /**
     * @returns string
     */
    getOptionLabelKey() {
        return 'full_name';
    }

    /**
     *
     * @param filters
     * @param config
     * @returns {Promise<AxiosResponse<T>>}
     */
    getWithFilters(filters, config = {}) {
        return axios.post('api/v1/users/list', filters, config);
    }

    getById(id) {
        return axios.get(`api/v1/users/${id}`);
    }
}
