import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';

Vue.use(Vuex);

export const actions = {
    setToken({ commit }, token) {
        commit('SET_TOKEN', token);
    },
    setLoggedInStatus({ commit }, status) {
        commit('SET_LOGGED_IN_STATUS', status);
    },
    setUser({ commit }, user) {
        commit('user/setUserData', user);
    },
    forceUserExit({ commit }, reason = null) {
        localStorage.clear();
        sessionStorage.clear();

        if (reason) {
            sessionStorage.setItem('lastLogoutReason', reason);
        }

        sessionStorage.setItem('logout', 'true');
    },
};

export const mutations = {
    SET_TOKEN(state, token) {
        state.token = token;
    },
    SET_LOGGED_IN_STATUS(state, status) {
        state.loggedInStatus = status;
    },
};

export const state = {
    token: null,
    loggedInStatus: false,
};

export const getters = {
    token: state => state.token,
    loggedInStatus: state => state.loggedInStatus,
};

const store = new Vuex.Store({
    actions,
    mutations,
    state,
    getters,
    modules,
    strict: process.env.NODE_ENV !== 'production',
});

for (const moduleName of Object.keys(modules)) {
    if (modules[moduleName].actions.hasOwnProperty('init')) {
        store.dispatch(`${moduleName}/init`);
    }
}

export default store;
