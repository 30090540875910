import TagService from '../../modules/tags/services/tag.service';

const state = {
    tags: null,
    service: null,
};

const getters = {
    tags: s => s.tags,
    service: s => s.service,
};

const mutations = {
    setTagsData(s, tagsData) {
        s.tags = tagsData;
    },
    setService(s, service) {
        s.service = service;
    },
};

const actions = {
    init(context) {
        context.commit('setService', new TagService());
    },
    setTagsData({ commit }, tags) {
        commit('setTagsData', tags);
    },
    async getTags({ dispatch, getters }) {
        let tags = getters.tags;
        if (!tags) {
            await dispatch('fetchTagsData');
            tags = getters.tags;
        }
        return tags;
    },
    async fetchTagsData({ commit, getters }) {
        const { service } = getters;
        if (service) {
            const { data } = await service.getAll({ disable_pagy: true });
            const tags = data.data;
            commit('setTagsData', tags);
            return tags;
        }
        return null;
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
