import ResourceService from '@/services/resource.service';
import axios from 'axios';
import { serialize } from '@/utils/url';

export default class ProjectService extends ResourceService {
    constructor(params = {}) {
        super();
        this.params = params;
    }

    getItemRequestUri(id) {
        return `api/v1/projects/${id}?id=${id}`;
    }

    getItem(id) {
        return axios.get(
            this.getItemRequestUri(id) + '&' + serialize({ with: ['users', 'defaultPriority', 'statuses'] }),
        );
    }

    getById(id) {
        return axios.get(`api/v1/projects/${id}`);
    }

    async getAll(options = {}) {
        return await axios.post('api/v1/project/list' + serialize(this.params), options);
    }

    deleteItem(id) {
        return axios.delete(`api/v1/projects/${id}?id=${id}`);
    }

    getWithFilters(filters, config = {}) {
        return axios.post('api/v1/project/list', filters, config);
    }

    save(data, isNew = false) {
        return axios.post('api/v1/projects', data);
    }

    update(data, id) {
        return axios.put(`api/v1/projects/${id}`, data);
    }

    getMembers(id) {
        return axios.get(`api/v1/projects/members?id=${id}`);
    }

    bulkEditMembers(data) {
        return axios.put('api/v1/projects/members_update', data);
    }

    getOptionLabelKey() {
        return 'name';
    }
}
