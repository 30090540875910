import StoreService from './store.service';
import axios from 'axios';

export default class ApiService extends StoreService {
    constructor(context) {
        super(context);
    }

    token() {
        return this.context.getters['user/token'];
    }

    async setUserData(user) {
        if (!user) {
            return this.context.dispatch('user/forceUserExit');
        }

        const currentCompany = await this.getCompanyData(user);
        this.userRoleAccess(user, currentCompany);

        this.context.dispatch('user/setUser', user);
        localStorage.setItem('user', JSON.stringify(user));
    }

    userRoleAccess(user, company) {
        const role_names = user.roles
            .filter(value => value.company_id === company.id)
            .flatMap(company => company.roles)
            .map(role => role.attributes.name);
        user.roles_access = {
            is_admin: role_names.includes('admin'),
            is_reporter: role_names.includes('reporter'),
            is_manager: role_names.includes('manager'),
            is_client: role_names.includes('client'),
            is_employee: role_names.includes('employee'),
            is_accountant: role_names.includes('accountant'),
        };
    }

    setUserToken(token) {
        if (token) {
            localStorage.setItem('access_token', token);
        } else {
            localStorage.removeItem('access_token');
        }
        this.context.dispatch('user/setToken', token);
    }

    setLoggedInStatus(status = true) {
        this.context.dispatch('user/setLoggedInStatus', status);
    }

    isLoggedIn() {
        return this.context.getters.loggedIn;
    }

    async checkApiAuth() {
        const user = await this.getUserData();
        await this.setUserData(user);
        this.setLoggedInStatus();
        return Promise.resolve;
    }

    async attemptLogin(credentials) {
        return await axios
            .post('api/v1/session', credentials, { ignoreCancel: true })
            .then(async ({ data }) => {
                await this.setUserToken(data.access_token);
                await this.setUserData(data.data.attributes);
                this.setLoggedInStatus();
                window.location.reload(); // need fix this problem

                return Promise.resolve(data);
            })
            .catch(response => {
                return Promise.reject(response);
            });
    }

    async attemptEmailAccept(key) {
        return await axios
            .post('api/v1/invitations/accept', { key: key }, { ignoreCancel: true })
            .then(({ data }) => {
                this.setUserToken(data.access_token);
                this.setUserData(data.data.attributes);
                this.setLoggedInStatus();
                window.location.reload(); // need fix this problem

                return Promise.resolve(data);
            })
            .catch(response => {
                return Promise.reject(response);
            });
    }

    attemptDesktopLogin(token) {
        const instance = axios.create();

        instance.defaults.headers.common['Authorization'] = `desktop ${token}`;

        return instance
            .post('/api/v1/auth/desktop-key', {}, { ignoreCancel: true })
            .then(({ data }) => {
                this.setUserToken(data.access_token);
                this.setUserData(data.data.attributes);
                this.setLoggedInStatus();

                return Promise.resolve(data);
            })
            .catch(response => {
                return Promise.reject(response);
            });
    }

    logout() {
        return this.context.dispatch('user/forceUserExit');
    }

    async getCompanySettings() {
        const { data } = await axios.get('api/v1/company-settings', { ignoreCancel: true });
        this.context.dispatch('user/setCompanySettings', data.data.attributes);

        return data.data.attributes;
    }

    async getCompanyData(user) {
        const companyService = this.context.getters['company/service'];
        const currentCompany = await companyService.getById(user.current_company_id);

        this.context.dispatch('company/setCompanyData', currentCompany);
        localStorage.setItem('company', JSON.stringify(currentCompany));

        return currentCompany;
    }

    async getUserData() {
        const user = JSON.parse(localStorage.getItem('user'));
        const service = this.context.getters['user/service'];
        const { data } = await service.getById(user.id);
        const currentUser = data.data.attributes;

        return currentUser;
    }

    async status() {
        try {
            const { status } = await axios.get('api/v1/status', { ignoreCancel: true });
            return status === 200;
        } catch (e) {
            return false;
        }
    }

    async backendVersion() {
        try {
            const { data } = await axios.get('version', { ignoreCancel: true });
            return data;
        } catch (e) {
            return null;
        }
    }

    serverUrl = axios.defaults.baseURL;
}
