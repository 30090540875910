const state = {
    currencies: null,
    colors: null,
};

const getters = {
    currencies: s => s.currencies,
    colors: s => s.colors,
};

const mutations = {
    setAvailableCurrencies(s, currencies) {
        s.currencies = currencies;
    },

    setColors(s, colors) {
        s.colors = colors;
    },
};

const actions = {
    init({ commit }) {
        const currencies = ['GBP', 'PLN', 'UAH', 'USD', 'EUR', 'JPY', 'AED', 'CAD'];
        const colors = {};

        currencies.forEach(currency => {
            switch (currency) {
                case 'GBP':
                    colors[currency] = '#FF5733';
                    break;
                case 'PLN':
                    colors[currency] = '#33FF57';
                    break;
                case 'UAH':
                    colors[currency] = '#3357FF';
                    break;
                case 'USD':
                    colors[currency] = '#FF33A6';
                    break;
                case 'EUR':
                    colors[currency] = '#FFC300';
                    break;
                case 'JPY':
                    colors[currency] = '#900C3F';
                    break;
                case 'AED':
                    colors[currency] = '#DAF7A6';
                    break;
                case 'CAD':
                    colors[currency] = '#581845';
                    break;
                default:
                    colors[currency] = '#000000';
                    break;
            }
        });

        commit('setAvailableCurrencies', currencies);
        commit('setColors', colors);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
