import RoleService from '@/services/resource/role.service';

const state = {
    roles: [],
    company_roles: [],
};

const getters = {
    roles: s => s.roles,
    company_roles: s => s.company_roles,
};

const mutations = {
    setRoles(s, roles) {
        s.roles = roles;
    },

    setCompanyRoles(s, roles) {
        s.company_roles = roles;
    },
};

const actions = {
    async loadRoles({ dispatch, state }) {
        if (state.roles && state.roles.length) {
            return state.roles;
        }

        const { data } = await new RoleService({ roles_type: 'project' }).getAll();
        dispatch('setRoles', data.data);

        return data.data;
    },

    async loadCompanyRoles({ dispatch, state }) {
        if (state.roles && state.company_roles.length) {
            return state.company_roles;
        }

        const { data } = await new RoleService().getAll();
        dispatch('setCompanyRoles', data.data);

        return data.data;
    },

    setCompanyRoles({ commit }, roles) {
        commit('setCompanyRoles', roles);
    },

    setRoles({ commit }, roles) {
        commit('setRoles', roles);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
